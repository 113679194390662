















































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import { Lookup, Params, Value } from "@/select2/base";
import request from "@/utils/request";
interface List<T> {
  results: T[];
}
const __sfc_main = {};
__sfc_main.props = {
  value: null,
  multiple: {
    key: "multiple",
    required: false,
    type: Boolean,
    default: false
  },
  endpoint: {
    key: "endpoint",
    required: true,
    type: String
  },
  lazy: {
    key: "lazy",
    required: false,
    type: Boolean,
    default: false
  },
  remote: {
    key: "remote",
    required: false,
    type: Boolean,
    default: false
  },
  idOnly: {
    key: "idOnly",
    required: false,
    type: Boolean,
    default: false
  },
  clearable: {
    key: "clearable",
    required: false,
    type: Boolean,
    default: false
  },
  reserveKeyword: {
    key: "reserveKeyword",
    required: false,
    type: Boolean,
    default: false
  },
  placeholder: {
    key: "placeholder",
    required: false,
    type: String,
    default: "Select"
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean
  },
  params: null
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const valueRef = toRef(props, "value");
  const paramsRef = toRef(props, "params");
  const emit = __ctx.emit;

  let list: Lookup[] = _ref([]);

  let loading = _ref(false);

  function handleChange(val: Value) {
    emit("change", val);
    emit("input", val);
  }

  let computedList = _computed(() => {
    if (props.value === null || props.value === undefined || props.idOnly) return list.value;

    if (Array.isArray(props.value)) {
      const vs = (props.value as Lookup[]);
      return [...list.value, ...vs].filter((e, i, arr) => i === arr.findIndex(t => t.id === e.id));
    }

    return list.value;
  });

  async function getMissing(list: Lookup[]) {
    if (!props.idOnly || !props.value) return list;
    const values = ((props.multiple ? props.value : [props.value]) as number[]);
    const missing = values.filter(id => !!id && !list.map(item => item.id).includes(id));
    if (missing.length === 0) return list;
    const requests = missing.map(id => request.get(`${props.endpoint}/${id}/`, {
      params: {
        nameonly: "1"
      }
    }));
    const responses = await Promise.all(requests);
    const data = responses.map(r => r.data);
    return [...list, ...data];
  }

  async function load(extraParams?: Params) {
    loading.value = true;

    try {
      const params = { ...(props.params || {}),
        ...(extraParams || {}),
        nameonly: "1",
        page_size: "50"
      };
      const response = await request.get(`${props.endpoint}/`, {
        params
      });
      list.value = await getMissing(response.data.results);
    } catch (e) {
      console.error(e);
    } finally {
      loading.value = false;
    }
  }

  function remoteLoad(name: string) {
    load({
      name
    });
  }

  onMounted(() => {
    if (!props.lazy) load();
  });
  watch(paramsRef, value => {
    load();
  });
  watch(valueRef, async value => {
    list.value = await getMissing(list.value);
  });
  return Object.assign({
    emit,
    loading,
    handleChange,
    computedList,
    remoteLoad
  }, {
    load
  });
};

export default __sfc_main;
