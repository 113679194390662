import { render, staticRenderFns } from "./PricingModelTable.vue?vue&type=template&id=b1089204&scoped=true&lang=pug&"
import script from "./PricingModelTable.vue?vue&type=script&lang=ts&"
export * from "./PricingModelTable.vue?vue&type=script&lang=ts&"
import style0 from "./PricingModelTable.vue?vue&type=style&index=0&id=b1089204&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1089204",
  null
  
)

export default component.exports