
























import { ref as _ref } from "@vue/composition-api";
import DecimalInput from "@/components/DecimalInput/index.vue";
import { pmDisplay } from "@/select";
import { PricingType } from "@/select";
import PricingModels, { PricingModelData } from "@/select2/pricingmodel";
import AgreementPrices, { AgreementPrice } from "@/select2/agreementprice";
interface AgreementPriceUI {
  enabled: boolean;
  id?: number;
  value: number;
  pricing_model: PricingModelData;
}
interface Params {
  agency?: string;
}
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  let pricingModels: PricingModelData[] = _ref([]);

  let loading = _ref(false);

  let loaded = _ref(false);

  let agreementPrices: AgreementPriceUI[] = _ref([]);

  function fromAgreementPrice(p: PricingModelData, ap?: AgreementPrice): AgreementPriceUI {
    const data: AgreementPriceUI = {
      enabled: ap !== undefined,
      value: ap?.value ?? p.value ?? 0,
      pricing_model: p
    };
    if (ap !== undefined) data.id = ap.id;
    return data;
  }

  async function load(params?: Params, agreement_id?: number) {
    loading.value = true; // @ts-ignore

    pricingModels.value = (await PricingModels.list(params)).results;
    let data: AgreementPrice[] = [];

    if (agreement_id) {
      data = (await AgreementPrices.list({
        agreement: agreement_id.toString()
      })).results;
    }

    agreementPrices.value = pricingModels.value.map(p => fromAgreementPrice(p, data.find(ap => ap.pricing_model === p.id)));
    loading.value = false;
    loaded.value = true;
  }

  async function save(agreement: number) {
    function toRequest(p: AgreementPriceUI): AgreementPrice {
      return { ...omit(["enabled"], p),
        agreement,
        pricing_model: p.pricing_model.id
      };
    }

    const newPrices = agreementPrices.value.filter(p => p.enabled && !p.id).map(toRequest);
    const existingPrices = agreementPrices.value.filter(p => p.id).map(toRequest);
    await Promise.all(newPrices.map(p => AgreementPrices.create(p)));
    await Promise.all(existingPrices.map(p => AgreementPrices.update(p.id, p)));
  }

  return Object.assign({
    pmDisplay,
    PricingType,
    loading,
    loaded,
    agreementPrices
  }, {
    load,
    save
  });
};

__sfc_main.components = Object.assign({
  DecimalInput
}, __sfc_main.components);
export default __sfc_main;
