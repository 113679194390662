

































































































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import DecimalInput from "@/components/DecimalInput/index.vue";
import { ContextSelector, FormatCheckboxSelector } from "@/components/selectors";
import NewInventorySelector from "@/components/NewInventorySelector.vue";
import { agreementStatusDisplay as statusDisplay, Device, FormatType, formatDisplay, getAvailableFormats, InventoryData } from "@/select";
import { PricingModelData } from "@/select/api/types";
import PricingModelTable from "./components/PricingModelTable.vue";
import Agreements, { AgreementData, AgreementForm, defaultAgreementData } from "@/select2/agreement";
import router from "@/router";
import Selector from "@/components/Selector.vue";
import { Lookup } from "@/select2/base";
import { Form, Message } from "element-ui";
import { Rules, nonemptyArray, requiredObject, requiredNumber } from "@/rules";
import Inventories from "@/select2/inventory";
type FormatMap = {
  [key: number]: number[];
};
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;
  const isTeamAdmin = User.roles.includes("team admin");
  const rules: Rules = {
    agency: [requiredObject],
    status: [requiredNumber],
    inventory: [requiredObject],
    formats: [nonemptyArray],
    devices: [nonemptyArray],
    cpm: [requiredNumber]
  };

  if (isTeamAdmin) {
    rules.advertiser = [requiredObject];
  }

  let agreement = _ref({ ...defaultAgreementData
  });

  let formatMap: FormatMap | null = _ref(null);

  let contexts: string[] = _ref([]);

  let loadingContexts = _ref(false);

  let loadingPricingModels = _ref(false);

  let pricing_models: PricingModelData[] = _ref([]);

  let availableDevices: number[] = _ref([]);

  let inventorySelector: InstanceType<typeof NewInventorySelector> = _ref(null);

  let pmTable: InstanceType<typeof PricingModelTable> = _ref(null);

  let form: InstanceType<typeof Form> = _ref(null);

  let saving = _ref(false);

  let advertiserSelector: InstanceType<typeof Selector> = _ref(null);

  let customName = _ref(false);

  const id = Number(router.currentRoute.params.id);
  const isEdit = !isNaN(id);
  onMounted(async () => {
    if (!isAdmin) {
      inventorySelector.value.load();
      pmTable.value.load(undefined, id);
      advertiserSelector.value.load();
    }

    if (isEdit) {
      agreement.value = await Agreements.get(id);
      customName.value = agreement.value.name !== "";

      if (isAdmin) {
        const agency = (agreement.value.agency as Lookup).id.toString();
        inventorySelector.value.load({
          agency
        });
        pmTable.value.load({
          agency
        }, id);
        advertiserSelector.value.load({
          agency
        });
      }

      await handleInventoryChange((agreement.value.inventory as InventoryData));
      handleFormatsChange(agreement.value.formats);
    }
  });

  function handleAgencyChange(val: Lookup) {
    const agency = val.id.toString();
    inventorySelector.value.load({
      agency
    });
    pmTable.value.load({
      agency
    });
    advertiserSelector.value.load({
      agency
    });
  }

  async function handleInventoryChange(val: InventoryData) {
    formatMap.value = await Inventories.listAction<FormatMap>(`${val.id}/available_formats/`);
  }

  async function handleFormatsChange(selected: number[]) {
    if (!formatMap.value) {
      availableDevices.value = [];
      return;
    }

    availableDevices.value = [...new Set(Object.entries(formatMap.value).filter(([k, v]) => selected.includes(Number(k))).map(([k, v]) => v).flat())];
  }

  let contextParams = _computed(() => {
    if (agreement.value.inventory === undefined) return {};
    const params = {
      inventories: (agreement.value.inventory as InventoryData).id
    };
    if (isAdmin) params.agencies = (agreement.value.agency as Lookup).id;
    return params;
  });

  async function save() {
    saving.value = true;

    try {
      const body = (agreement.value as AgreementData);
      let id: number;
      const data: AgreementForm = { ...body,
        inventory: body.inventory?.id,
        advertiser: body.advertiser === null ? null : body.advertiser.id,
        contexts: body.contexts.map(c => c.id)
      };

      if (isAdmin) {
        data.agency = body.agency?.id;
      }

      if (isEdit) {
        const response = await Agreements.update((agreement.value.id as number), data);
        id = response.id;
      } else {
        const response = await Agreements.create(data);
        id = response.id;
      }

      await pmTable.value.save(id);
      const displayName = `${(agreement.value.inventory as InventoryData).name} - ${agreement.value.formats.map(formatDisplay).join(", ")} - ${agreement.value.devices.map(d => Device[d]).join(", ")}`;
      Message.success(`${isEdit ? "Updated" : "Created"} agreement '${displayName}'`);
      router.push("/agreements");
    } catch (e) {
      console.error(e);
      Message.error("error");
    } finally {
      saving.value = false;
    }
  }

  function handleSave() {
    form.value.validate(async valid => {
      if (valid) {
        save();
      }
    });
  }

  function removeCustomName() {
    customName.value = false;
    agreement.value.name = "";
  }

  let availableFormats = _computed(() => {
    const displayKeys = [1, 2, 3, 4, 5, 6, 7];
    const videoKeys = [8, 9, 10];
    if (formatMap.value === null) return [];
    const available = Object.entries(formatMap.value).filter(([k, v]) => v.length > 0).map(([k, v]) => Number(k)).flat();
    if (formatType.value === null) return available;
    if (formatType.value === FormatType.Display) return available.filter(f => displayKeys.includes(f));
    if (formatType.value === FormatType.Video) return available.filter(f => videoKeys.includes(f));
  });

  const devices = Array.from(Array(3).keys()).map(i => i + 1);

  let formatType = _computed(() => {
    if (!agreement.value || !agreement.value.formats) return null;
    const displayKeys = [1, 2, 3, 4, 5, 6, 7];
    const videoKeys = [8, 9, 10];
    if (displayKeys.some(n => agreement.value.formats.includes(n))) return FormatType.Display;
    if (videoKeys.some(n => agreement.value.formats.includes(n))) return FormatType.Video;
    return null;
  });

  const statuses = Array.from(Array(5).keys()).map(i => i + 1);

  let defaultName = _computed(() => {
    if (!agreement.value.inventory) return ""; // @ts-ignore

    if (agreement.value.advertiser === null) return agreement.value.inventory.name; // @ts-ignore

    return `${agreement.value.inventory.name} - ${agreement.value.advertiser.name}`;
  });

  return {
    statusDisplay,
    Device,
    isAdmin,
    rules,
    agreement,
    loadingPricingModels,
    pricing_models,
    availableDevices,
    inventorySelector,
    pmTable,
    form,
    saving,
    advertiserSelector,
    customName,
    handleAgencyChange,
    handleInventoryChange,
    handleFormatsChange,
    contextParams,
    handleSave,
    removeCustomName,
    availableFormats,
    devices,
    statuses,
    defaultName
  };
};

__sfc_main.components = Object.assign({
  DecimalInput,
  FormatCheckboxSelector,
  NewInventorySelector,
  PricingModelTable,
  Selector
}, __sfc_main.components);
export default __sfc_main;
