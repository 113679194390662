
































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import { groupInventories } from "@/select/inventory";
import { Lookup } from "@/select2/base";
import Inventories, { InventoryData } from "@/select2/inventory";
type Value = InventoryData | InventoryData[] | null;
interface Props {
  value?: Value;
  multiple?: boolean;
  lazy?: boolean;
  remote?: boolean;
}
interface Emits {
  (e: "input", value: Value): void;
}
interface Group {
  label: string;
  inventories: InventoryData[];
}
interface Params {
  name?: string;
  agency?: string;
}
const __sfc_main = {};
__sfc_main.props = {
  value: null,
  multiple: {
    key: "multiple",
    required: false,
    type: Boolean,
    default: false
  },
  lazy: {
    key: "lazy",
    required: false,
    type: Boolean,
    default: false
  },
  remote: {
    key: "remote",
    required: false,
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  let inventories: InventoryData[] = _ref([]);

  let loading = _ref(false);

  let computedList = _computed(() => {
    if (props.value === null || props.value === undefined) return inventories.value;

    if (!Array.isArray(props.value)) {
      const ids = inventories.value.map(item => item.id);
      const isLookup = typeof props.value === "object";
      if (isLookup && !ids.includes(props.value.id)) return [...inventories.value, props.value];
    }

    if (Array.isArray(props.value)) {
      const vs = (props.value as Lookup[]);
      return [...inventories.value, ...vs].filter((e, i, arr) => i === arr.findIndex(t => t.id === e.id));
    }

    return inventories.value;
  });

  async function load(params?: Params) {
    const query = { ...params,
      page_size: "50"
    };
    loading.value = true;
    const data = await Inventories.list(query);
    inventories.value = data.results;
    loading.value = false;
  }

  function remoteLoad(name: string) {
    load({
      name
    });
  }

  onMounted(() => {
    if (!props.lazy) load();
  });

  let groups: Group[] = _computed(() => groupInventories(computedList.value));

  let isBundle: boolean = _computed(() => {
    if (props.multiple) return false;
    if (props.value === undefined) return false;
    return (props.value as InventoryData).is_bundle;
  });

  let siteNames: string[] = _computed(() => {
    if (props.multiple || props.value === undefined) {
      return [];
    }

    return [...new Set((props.value as InventoryData).websites.map(s => s.split(" ")[0]))];
  });

  return Object.assign({
    emit,
    loading,
    remoteLoad,
    groups,
    isBundle,
    siteNames
  }, {
    load
  });
};

export default __sfc_main;
