import { ListParams, ListQuery, RouterQuery } from "./api/base"
import Generate from "./api/crud"
import { Lookup } from "./base"

export interface Params extends ListParams {
	name?: string
}

export interface Inventory {
	id?: number
	name: string
	is_available: boolean
	is_bundle: boolean
	websites: string[]
	publisher: Lookup | number | null
	agencies?: string[] | null
}

export interface InventoryForm extends Omit<Inventory, "id"> {}
export interface InventoryData extends Inventory {
	id: number
	publisher: Lookup
}

export const defaultInventoryData: Inventory = {
	name: "",
	is_available: true,
	is_bundle: false,
	websites: [],
	publisher: null,
	agencies: [],
}

export class Params extends ListQuery {
	name?: string = ""

	constructor(routerQuery: RouterQuery) {
		super(routerQuery)
		if (typeof routerQuery.name === "string") this.name = routerQuery.name
	}

	toRouterQuery() {
		return {
			...super.toRouterQuery(),
			name: this.name ?? "",
		}
	}
}

export default Generate<InventoryForm, InventoryData>("/inventories")
