import Generate from "./api/crud"

export interface AgreementPrice {
	id: number
	value: number | null
	agreement: number
	pricing_model: number
}

export default Generate<AgreementPrice, AgreementPrice>("/agreementprices")
